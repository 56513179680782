export default [
  // *===============================================---*
  // *--------- EXPEDIENTES ----------------------------*
  // *===============================================---*
  {
    path: '/expedientes/list',
    name: 'expedientes-list',
    component: () => import('@/views/expedientes/List.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
    },
  },
  {
    path: '/expedientes/list/:ccaa',
    name: 'expedientes-list-ccaa',
    component: () => import('@/views/expedientes/List.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
    },
  },
  {
    path: '/expedientes/printer/:expediente',
    name: 'expedientes-printer-expediente',
    component: () => import('@/views/expedientes/PrinterDoc.vue'),
    meta: {
      resource: 'Auth',
      action: 'write',
    },
  },
]
