import firebase from 'firebase/app'

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyA637YnyUrFjomGmpRSfUg9GdBjN6KZ-MU',
  authDomain: 'app-users-cloud.firebaseapp.com',
  databaseURL: 'https://app-users-cloud.firebaseio.com',
  projectId: 'app-users-cloud',
  storageBucket: 'ga-catastro',
  messagingSenderId: '1018354841714',
}

firebase.initializeApp(config)
// console.log(firebase)
