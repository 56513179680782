/* eslint-disable no-restricted-syntax */
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'

let unSuscribeExptes = null

export default {
  namespaced: true,
  state: {
    ca: [],
    provincias: [],
    expedientes: [],
    catastro: null,
    users: [],
  },
  getters: {},
  mutations: {
    SET_CA(state, payload) {
      state.ca = payload
    },
    SET_EXPEDIENTES(state, payload) {
      state.expedientes = payload
    },
    SET_PROVINCIAS(state, payload) {
      state.provincias = payload
    },
    SET_CATASTRO(state, payload) {
      state.catastro = payload
    },
    ADD_USER(state, payload) {
      state.users.push(payload)
    },
  },
  actions: {
    setCA(ctx) {
      return new Promise((resolve, reject) => {
        if (ctx.state.ca.length) return
        const dbRef = firebase.database().ref()
        dbRef.child('ca')
          .once('value')
          .then(snapshot => {
            if (snapshot.exists()) {
              // console.log(snapshot.val())
              ctx.commit('SET_CA', snapshot.val())
              resolve(snapshot.val())
            } else {
              // console.log('No existen datos')
              resolve(null)
            }
          })
          .catch(error => {
            console.error(error)
            reject(error)
          })
      })
    },
    setProvincias(ctx) {
      return new Promise((resolve, reject) => {
        if (ctx.state.provincias.length) return
        const dbRef = firebase.database().ref()
        dbRef.child('provincias')
          .once('value')
          .then(snapshot => {
            if (snapshot.exists()) {
              ctx.commit('SET_PROVINCIAS', snapshot.val())
              resolve(snapshot.val())
            } else {
              // console.log('No existen datos')
              resolve(null)
            }
          })
          .catch(error => {
            console.error(error)
            reject(error)
          })
      })
    },
    setExpedientes(ctx) {
      return new Promise(resolve => {
        if (unSuscribeExptes) return
        const dbRef = firebase.database()
        unSuscribeExptes = dbRef.ref('expedientes')
          .on('value', snapshot => {
            if (snapshot.exists()) {
              // Transformar en Array
              const obj = snapshot.val()
              const arr = Object.keys(obj).map(k => obj[k])
              // ---
              ctx.commit('SET_EXPEDIENTES', arr)
              resolve(snapshot.val())
            } else {
              // console.log('No existen datos')
              resolve([])
            }
          })
      })
    },
    getCatastro(ctx, refCatastro) {
      return new Promise((resolve, reject) => {
        if (ctx.state.catastro && ctx.state.catastro.id === refCatastro) return
        // console.log(`catastro/${refCatastro}`)
        const dbRef = firebase.database().ref()
        dbRef.child('catastro')
          .child(`${refCatastro}`)
          .once('value')
          .then(snapshot => {
            if (snapshot.exists()) {
              const catastro = snapshot.val()
              catastro.id = refCatastro
              ctx.commit('SET_CATASTRO', catastro)
              resolve(snapshot.val())
            } else {
              // console.log('No existen datos')
              resolve(null)
            }
          })
          .catch(error => {
            console.error(error)
            reject(error)
          })
      })
    },
    getUser(ctx, id) {
      return new Promise((resolve, reject) => {
        // console.log(`getUser(${id})`)
        const user = ctx.state.users.filter(u => u.id === id)[0]
        if (user) return
        const dbRef = firebase.database().ref()
        dbRef.child('users')
          .child(`${id}`)
          .once('value')
          .then(snapshot => {
            if (snapshot.exists()) {
              const usuario = snapshot.val()
              usuario.id = id
              ctx.commit('ADD_USER', usuario)
              resolve(snapshot.val())
            } else {
              // console.log('No existen datos')
              resolve(null)
            }
          })
          .catch(error => {
            console.error(error)
            reject(error)
          })
      })
    },
    unsuscribe() {
      // if (unSuscribeExptes) unSuscribeExptes.off()
      firebase.database().ref().off()
    },
  },
}
