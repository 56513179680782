import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import expedientes from './expedientes'
import auth from './auth'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import ecommerce from './ecommerce'
// import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
// import clientes from './clientes'
// import pedidos from './pedidos'
// import productos from './productos'
// import vehiculos from './vehiculos'
// import rutas from './rutas'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    expedientes,
    auth,
    app,
    appConfig,
    verticalMenu,
    ecommerce,
    // clientes,
    // pedidos,
    // productos,
    // vehiculos,
    // rutas,
    // 'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
